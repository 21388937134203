window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('simplelightbox');
require('./rwd-checker');
require('./dropdown-menu');
require('./mobile-menu');
require('./leaflet/leaflet');

$(function () {
    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });
});